// Add your custom JS here.
document.addEventListener("DOMContentLoaded", function () {
  AOS.init({
    // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    //offset: 120, // offset (in px) from the original trigger point
    //delay: 0, // values from 0 to 3000, with step 50ms
    //duration: 400, // values from 0 to 3000, with step 50ms
    easing: 'ease-in-out', // default easing for AOS animations
    once: true, // whether animation should happen only once - while scrolling down
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
  });
});